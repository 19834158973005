import { environment } from './../../environments/environment';
import { AdminSelectPage } from './../admin-select/admin-select.page';
import { AdminService } from './../services/admin.service';
import { SettingsPage } from './../settings/settings.page';
import { NavController, ModalController } from '@ionic/angular';
import { AuthenticationService } from "./../services/authentication.service";
import { ConfigService } from "./../services/config.service";
import { PageService } from "./../services/page.service";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { RequestPermissionComponent } from '../request-permission/request-permission.component';
import { Title } from '@angular/platform-browser';
import { DokaI18NextService } from '@doka/i18next';
@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {

  @Input() isMenuOpen: boolean;
  environment = environment;

  constructor(
    public pageService: PageService,
    public configService: ConfigService,
    public authenticationService: AuthenticationService,
    private navCtrl: NavController,
    private router: Router,
    private modalCtrl: ModalController,
    public adminService: AdminService,
    private title: Title,
    private translate: DokaI18NextService
  ) { }

  ngOnInit() { }

  ngOnDestroy() {

  }

  logout() {
    this.authenticationService.logout();
  }

  navigate(page: string) {
    this.navCtrl.navigateForward(page, { animated: false });
  }

  checkIfMarked(site: string) {
    let splittedUrl = this.router.url.split("/");
    return splittedUrl[1].includes(site);
  }

  onOpenSettings() {
    this.modalCtrl.create({
      component: SettingsPage
    }).then(modalEl => {
      modalEl.present();
    })
  }

  onSwitchMandant() {
    this.modalCtrl.create({
      component: AdminSelectPage
    }).then(modalEl => {
      modalEl.present();
    });
  }

  onRequestPermission() {
    this.modalCtrl.create({
      component: RequestPermissionComponent,
      cssClass: 'largeModal'
    }).then(modalEl => {
      modalEl.present();
    });
  }

  openDokaID() {
    window.open(environment.idAppLink, "_blank");
  }

  openYardCam() {
    window.open(environment.yardCamLink, "_blank");
  }

  openDokaYardQC() {
    window.open(environment.yardQCUrl, "_blank");
  }
}
