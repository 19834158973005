<div class="itemWrapper">
    <ion-icon 
        color="white"
        name="square-outline"
        *ngIf="!checked"
    >
    </ion-icon>
    <ion-icon
        color="primary"
        name="checkbox"
        *ngIf="checked"
    >
    </ion-icon>
</div>