<ion-header>
  <ion-toolbar class="totalHeader">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ tempPicking._id }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-item>
      {{ "p_id" | i18nextEager }}:
      <span class="textRight">
        {{ tempPicking._id }}
      </span>
    </ion-item>
    <ion-item>
      {{ "YARDCAM_LINKS" | i18nextEager }}
      <span class="textRight"
        ><a class="yardcamLink" (click)="openYardcam(tempPicking._id, 'CM')">{{
          "NEW_YC_LINK" | i18nextEager
        }}</a>
      </span>
    </ion-item>
    <ion-item>
      {{ "salesIds" | i18nextEager }}:
      <span class="textRight">
        <a class="yardcamLink" (click)="openYardcam(tempPicking.salesIds[0])">{{
          tempPicking.salesIds
        }}</a>
      </span>
    </ion-item>
    <ion-item>
      {{ "COMPANY" | i18nextEager }}:
      <span class="textRight">{{ tempPicking?.custName }}</span>
    </ion-item>
    <ion-item
      >{{ "projectName" | i18nextEager }}:
      <span class="textRight"> {{ tempPicking?.projectName }}</span>
    </ion-item>
    <ion-item
      >{{ "DLV_ADDRESS" | i18nextEager }}:
      <span class="textRight"> {{ tempPicking?.dlvAddress }}</span>
    </ion-item>
    <ion-item>
      {{ "DLV_TERMS" | i18nextEager }}
      <span class="textRight">{{ tempPicking?.dlvTerm }}</span>
    </ion-item>
    <ion-item>
      {{ "DLV_MODE" | i18nextEager }}
      <span class="textRight">{{ tempPicking?.dlvModeId }}</span>
    </ion-item>
    <ion-item>
      {{ "PICKED_WEIGHT" | i18nextEager }}
      <span class="textRight">{{ calculatePickedWeight() }}</span>
    </ion-item>
    <ion-item>
      {{ "REMOTE_PICKERS" | i18nextEager }}
      <span class="textRight">{{ tempPicking?.picker }}</span>
    </ion-item>
  </ion-list>
</ion-content>
