import { DokaI18NextService } from '@doka/i18next';
import { ConfigService } from "../services/config.service";
import {
  Component,
  OnInit,
  ApplicationRef,
  OnDestroy,
  ViewChild,
  Input,
} from "@angular/core";
import { ModalController, IonReorderGroup } from "@ionic/angular";
import * as $ from "jquery";
import { Subscription } from "rxjs";

@Component({
  selector: "app-config",
  templateUrl: "./column-config.page.html",
  styleUrls: ["./column-config.page.scss"],
})
export class ColumnConfigPage implements OnInit, OnDestroy {
  @Input() type: string;
  @ViewChild("returnReorder") returnReorder: IonReorderGroup;
  @ViewChild("returnItemReorder") returnItemReorder: IonReorderGroup;
  constructor(
    private modalCtrl: ModalController,
    private appRef: ApplicationRef,
    public configService: ConfigService,
    public translate: DokaI18NextService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  completeReorder(reorder: IonReorderGroup) {
    this.returnReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("returnReorderColumn");
      const tempArray: { value: string; size: string }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.configService.config[this.type][i].value
          )
        );
        const toInsert = this.configService.config[this.type].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        console.log(toInsert);
        tempArray.push(
          toInsert[0]
        );
      }
      this.configService.config[this.type] = tempArray;
      console.log(this.configService.config[this.type]);
    });
  }

  /* Closes Modal, emits an Event so Lists can adjust to Font-Size change */
  closeSettings() {
    this.configService.saveConfig(this.configService.config);
    this.modalCtrl.dismiss();
    this.appRef.tick();
  }
}
