<ion-header>
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="closeSettings()">
        <ion-icon name="close" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title color="primary"
      >{{ "COLUMN_SETTINGS" | i18nextEager }}</ion-title
    >
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="closeSettings()">
        <ion-icon name="save" slot="icon-only" color="primary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-list lines="none">
    <ion-item-group>
      <ion-item-divider
        >{{ type | i18nextEager }}</ion-item-divider
      >
      <ion-reorder-group disabled="false" (ionItemReorder)="completeReorder(returnReorder)" #returnReorder>
        <ion-item
          *ngFor="let column of this.configService.config[type]"
          class="returnReorderColumn"
        >
          {{ column.value | i18nextEager }}
          <ion-reorder slot="end">
            <ion-icon name="menu-sharp"></ion-icon>
          </ion-reorder>
        </ion-item>
      </ion-reorder-group>
    </ion-item-group>
  </ion-list>
</ion-content>
