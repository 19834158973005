import { ArticleGroup } from "./articleGroup.model";
import { Group } from "./group";
import { TimeSpan } from "./timespan.model";

export class ReturnLineItemProcessingTimeModel {
    constructor(
        articleId: string,
        articleIdentifier: string,
        articleName: string,
        averageTime: number,
        totalTime: number,
        okQuantity: number,
        reconQuantity: number,
        defectQuantity: number,
        itemGroup: ArticleGroup
    ) {
        this.articleId = articleId;
        this.articleIdentifier = articleIdentifier;
        this.articleName = articleName;
        this.averageTime = TimeSpan.fromSeconds(averageTime);
        this.totalTime = TimeSpan.fromSeconds(totalTime);
        this.okQuantity = okQuantity;
        this.reconQuantity = reconQuantity;
        this.defectQuantity = defectQuantity;
        this.itemGroup = itemGroup;
    }

    articleId: string;
    articleIdentifier: string;
    articleName: string;
    averageTime: TimeSpan;
    totalTime: TimeSpan;
    okQuantity: number;
    reconQuantity: number;
    defectQuantity: number;
    itemGroup: ArticleGroup;
}