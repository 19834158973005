<ion-header>
  <ion-toolbar class="totalHeader">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon name="close-sharp" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "picker" | i18nextEager }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="savePicker()">
        <ion-icon name="save-sharp" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list lines="none">
    <ion-item *ngFor="let picker of activePickers">
      {{ picker.userFirstname }} {{ picker.userLastname }}
      <ion-icon (click)="removePicker(picker)" class="removePickerIcon" name="remove-circle-outline" slot="end"></ion-icon>
    </ion-item>
    <ion-item (click)="openAddPicker()" button>
      <ion-icon class="addPicker" name="add-sharp"></ion-icon>
    </ion-item>
  </ion-list>
</ion-content>