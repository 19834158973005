import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { FullPickingList } from '../models/pickingLists/full-picking-list-model';
import { WebService } from '../services/web.service';
import { DokaI18NextService } from '@doka/i18next';
import { AddPickerComponent } from './add-picker/add-picker.component';

@Component({
  selector: 'app-pickers-list',
  templateUrl: './pickers-list.component.html',
  styleUrls: ['./pickers-list.component.scss'],
})
export class PickersListComponent implements OnInit {

  @Input("pickingLists") pickingLists: FullPickingList[];

  activePickers: { userId: string, userFirstname: string, userLastname: string, pickingListIds: string[] }[] = [];
  bufferActivePickers: { userId: string, userFirstname: string, userLastname: string, pickingListIds: string[] }[] = [];
  pickersToRemove: { userId: string, userFirstname: string, userLastname: string, pickingListIds: string[] }[] = [];
  pickersToAdd: { userId: string, userFirstname: string, userLastname: string, pickingListIds: string[] }[] = [];

  constructor(
    private modalCtrl: ModalController,
    private webService: WebService,
    private loadingCtrl: LoadingController,
    private translate: DokaI18NextService
  ) { }

  ngOnInit() {
    for (let pickingList of this.pickingLists) {
      if (pickingList.pickers) {
        for (let picker of pickingList.pickers) {
          if (picker.isActive) {
            console.log(picker);
            let activePicker = this.activePickers.find(ap => ap.userId === picker.userId);
            if (activePicker) {
              activePicker.pickingListIds.push(pickingList.id);
            } else {
              activePicker = { userId: picker.userId, userFirstname: picker.firstName, userLastname: picker.lastName, pickingListIds: [] };
              activePicker.userId = picker.userId;
              activePicker.pickingListIds.push(pickingList.id);
              this.activePickers.push(activePicker);
            }
          }
        }
      }
    }
    this.bufferActivePickers = [...this.activePickers];
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  removePicker(activePicker) {
    let pickerIndex = this.activePickers.findIndex(ap => ap.userId === activePicker.userId);
    this.activePickers.splice(pickerIndex, 1);
    if (this.bufferActivePickers.findIndex(p => p.userId === activePicker.userId) !== -1) {
      this.pickersToRemove.push(activePicker);
    } else {
      let pickerAddIndex = this.pickersToAdd.findIndex(p => p.userId === activePicker.userId);
      this.pickersToAdd.splice(pickerAddIndex, 1);
    }
  }

  async savePicker() {
    console.log(this.pickersToRemove);
    let loadingEl = await this.loadingCtrl.create({
      message: this.translate.instant("SAVE_PICKERS_LOADING"),
      spinner: 'crescent'
    });
    await loadingEl.present();
    for (let picker of this.pickersToRemove) {
      for (let pickingListId of picker.pickingListIds) {
        try {
          await this.webService.deactivatePickerFromPickingListById(pickingListId, picker.userId);
        } catch (err) {

        }
      }
    }
    for (let picker of this.pickersToAdd) {
      for (let pickingListId of picker.pickingListIds) {
        await this.webService.assignPickerToPickingList(pickingListId, picker.userId);
      }
    }
    await loadingEl.dismiss();
    this.modalCtrl.dismiss();
  }

  async openAddPicker() {
    let modalEl = await this.modalCtrl.create({
      component: AddPickerComponent,
      componentProps: {
        excludedUsers: this.activePickers,
        loadUsersRole: "Delivery App-Picking"
      }
    });
    modalEl.onDidDismiss().then(res => {
      console.log(res);
      if (res.data) {
        let pickingListIds: string[] = [];
        for (let pickingList of this.pickingLists) {
          pickingListIds.push(pickingList.id);
        }
        this.pickersToAdd.push({
          userId: res.data.id,
          userFirstname: res.data.firstName,
          userLastname: res.data.lastName,
          pickingListIds: pickingListIds
        });
        this.activePickers.push({
          userId: res.data.id,
          userFirstname: res.data.firstName,
          userLastname: res.data.lastName,
          pickingListIds: pickingListIds
        });
      }
    });
    await modalEl.present();
  }

}
