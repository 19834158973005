import { PickingSlide } from './../enum/pickingSlide.enum';
import { AdminService } from './../services/admin.service';
import { DokaI18NextService } from '@doka/i18next';
import { ConfigService } from './../services/config.service';
import { IonReorderGroup, LoadingController, ModalController } from '@ionic/angular';
import { ApplicationRef, Component, OnInit, ViewChild } from '@angular/core';
import { Overview } from '../enum/overview.enum';
import { environment } from 'src/environments/environment';
import { DatabaseService } from '../services/database.service';
import { AuthenticationService } from '../services/authentication.service';
import { MeasurementSystem } from '../enum/measurementSystem.enum';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {
  appVersion: string = environment.appVersion;
  @ViewChild("returnReorder") returnReorder: IonReorderGroup;
  @ViewChild("returnItemsReorder") returnItemsReorder: IonReorderGroup;
  @ViewChild("openReturnItemReorder") openReturnItemReorder: IonReorderGroup;
  @ViewChild("pickingReorder") pickingReorder: IonReorderGroup;
  @ViewChild("TOReorder") TOReorder: IonReorderGroup;
  @ViewChild("TOItemsReorder") TOItemsReorder: IonReorderGroup;
  @ViewChild("ReconReorder") ReconReorder: IonReorderGroup;
  @ViewChild("reconItemReorder") reconItemReorder: IonReorderGroup;
  @ViewChild("openItemReorder") openItemReorder: IonReorderGroup;

  public isReturnColumns = false;
  public isReturnItemColumns = false;
  public isOpenReturnItemColumns = false;
  public isPickingColumns = false;
  public isPickingItemColumns = false;
  public isOutboundColumns = false;
  public isReconColumns = false;
  public isReconItemColumns = false;
  public isOpenItemColumns = false;
  public overview = Overview;
  public pickingSlide = PickingSlide;

  measurementSystem = MeasurementSystem;

  constructor(
    private modalCtrl: ModalController,
    public cfgService: ConfigService,
    private translate: DokaI18NextService,
    public adminService: AdminService,
    public databaseService: DatabaseService,
    public authenticationService: AuthenticationService,
    private appRef: ApplicationRef
  ) { }

  ngOnInit() {
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  onChange() {
    this.cfgService.saveConfig(this.cfgService.config);
    this.appRef.tick();
    console.log(this.cfgService.config.appLanguage);
    this.translate.changeLanguage(this.cfgService.config.appLanguage);
  }

  onFontChange() {
    this.cfgService.saveConfig(this.cfgService.config);
    this.cfgService.changeFont();
  }

  completeReorderReturn(reorder: IonReorderGroup) {
    this.returnReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("returnReorderColumn");
      const tempArray: { value: string; size: string; display: boolean }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.cfgService.config["returnColumns"][i].value
          )
        );
        const toInsert = this.cfgService.config["returnColumns"].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        tempArray.push(
          toInsert[0]
        );
      }
      console.log(tempArray);
      this.cfgService.config["returnColumns"] = tempArray;
      this.cfgService.saveConfig(this.cfgService.config);
      console.log(this.cfgService.config["returnColumns"]);
      this.cfgService.newOrder.next(true);
    });
  }

  completeReorderPicking(reorder: IonReorderGroup) {
    this.pickingReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("pickingReorderColumn");
      const tempArray: { value: string; size: string; display: boolean }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.cfgService.config["pickingColumns"][i].value
          )
        );
        const toInsert = this.cfgService.config["pickingColumns"].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        console.log(toInsert);
        tempArray.push(
          toInsert[0]
        );
      }
      this.cfgService.config["pickingColumns"] = tempArray;
      this.cfgService.saveConfig(this.cfgService.config);
      console.log(this.cfgService.config["pickingColumns"]);
      this.cfgService.newOrder.next(true);
    });
  }

  completeReorderTO(reorder: IonReorderGroup) {
    this.TOReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("TOReorderColumn");
      const tempArray: { value: string; size: string; display: boolean }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.cfgService.config["transportOrderColumns"][i].value
          )
        );
        const toInsert = this.cfgService.config["transportOrderColumns"].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        console.log(toInsert);
        tempArray.push(
          toInsert[0]
        );
      }
      this.cfgService.config["transportOrderColumns"] = tempArray;
      this.cfgService.saveConfig(this.cfgService.config);
      console.log(this.cfgService.config["transportOrderColumns"]);
      this.cfgService.newOrder.next(true);
    });
  }

  completeReorderTOItems(reorder: IonReorderGroup) {
    this.TOItemsReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("TOItemsReorderColumn");
      const tempArray: { value: string; size: string; display: boolean }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.cfgService.config["transportOrderColumns"][i].value
          )
        );
        const toInsert = this.cfgService.config["pickingItemColumns"].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        console.log(toInsert);
        tempArray.push(
          toInsert[0]
        );
      }
      this.cfgService.config["pickingItemColumns"] = tempArray;
      this.cfgService.saveConfig(this.cfgService.config);
      console.log(this.cfgService.config["pickingItemColumns"]);
      this.cfgService.newOrder.next(true);
    });
  }
  completeReorderReturnItems(reorder: IonReorderGroup) {
    this.returnItemsReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("ReturnItemsReorderColumn");
      const tempArray: { value: string; size: string; display: boolean }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.cfgService.config["returnItemColumns"][i].value
          )
        );
        const toInsert = this.cfgService.config["returnItemColumns"].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        console.log(toInsert);
        tempArray.push(
          toInsert[0]
        );
      }
      this.cfgService.config["returnItemColumns"] = tempArray;
      this.cfgService.saveConfig(this.cfgService.config);
      console.log(this.cfgService.config["returnItemColumns"]);
      this.cfgService.newOrder.next(true);
    });
  }

  completeReconReorder(reorder: IonReorderGroup) {
    this.ReconReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("reconReorderColumn");
      const tempArray: { value: string; size: string; display: boolean }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.cfgService.config["reconColumns"][i].value
          )
        );
        const toInsert = this.cfgService.config["reconColumns"].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        console.log(toInsert);
        tempArray.push(
          toInsert[0]
        );
      }
      this.cfgService.config["reconColumns"] = tempArray;
      this.cfgService.saveConfig(this.cfgService.config);
      console.log(this.cfgService.config["reconColumns"]);
      this.cfgService.newOrder.next(true);
    }); this.returnItemsReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("reconReorderColumn");
      const tempArray: { value: string; size: string; display: boolean }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.cfgService.config["reconColumns"][i].value
          )
        );
        const toInsert = this.cfgService.config["reconColumns"].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        console.log(toInsert);
        tempArray.push(
          toInsert[0]
        );
      }
      this.cfgService.config["reconColumns"] = tempArray;
      this.cfgService.saveConfig(this.cfgService.config);
      console.log(this.cfgService.config["reconColumns"]);
      this.cfgService.newOrder.next(true);
    });
  }

  completeReconItemReorder(reorder: IonReorderGroup) {
    this.reconItemReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("reconItemReorderColumn");
      const tempArray: { value: string; size: string; display: boolean }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.cfgService.config["reconItemColumns"][i].value
          )
        );
        const toInsert = this.cfgService.config["reconItemColumns"].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        console.log(toInsert);
        tempArray.push(
          toInsert[0]
        );
      }
      this.cfgService.config["reconItemColumns"] = tempArray;
      this.cfgService.saveConfig(this.cfgService.config);
      console.log(this.cfgService.config["reconItemColumns"]);
      this.cfgService.newOrder.next(true);
    }); this.returnItemsReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("reconItemReorderColumn");
      const tempArray: { value: string; size: string; display: boolean }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.cfgService.config["reconItemColumns"][i].value
          )
        );
        const toInsert = this.cfgService.config["reconItemColumns"].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        console.log(toInsert);
        tempArray.push(
          toInsert[0]
        );
      }
      this.cfgService.config["reconItemColumns"] = tempArray;
      this.cfgService.saveConfig(this.cfgService.config);
      console.log(this.cfgService.config["reconItemColumns"]);
      this.cfgService.newOrder.next(true);
    });
  }

  completeOpenItemReorder(reorder: IonReorderGroup) {
    this.openItemReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("openItemReorderColumn");
      const tempArray: { value: string; size: string; display: boolean }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.cfgService.config["openItemColumns"][i].value
          )
        );
        const toInsert = this.cfgService.config["openItemColumns"].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        console.log(toInsert);
        tempArray.push(
          toInsert[0]
        );
      }
      this.cfgService.config["openItemColumns"] = tempArray;
      this.cfgService.saveConfig(this.cfgService.config);
      console.log(this.cfgService.config["openItemColumns"]);
      this.cfgService.newOrder.next(true);
    }); this.returnItemsReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("openItemReorderColumn");
      const tempArray: { value: string; size: string; display: boolean }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.cfgService.config["openItemColumns"][i].value
          )
        );
        const toInsert = this.cfgService.config["openItemColumns"].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        console.log(toInsert);
        tempArray.push(
          toInsert[0]
        );
      }
      this.cfgService.config["openItemColumns"] = tempArray;
      this.cfgService.saveConfig(this.cfgService.config);
      console.log(this.cfgService.config["openItemColumns"]);
      this.cfgService.newOrder.next(true);
    });
  }

  completeOpenReturnItemReorder(reorder: IonReorderGroup) {
    this.openReturnItemReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("openReturnItemReorderColumn");
      const tempArray: { value: string; size: string; display: boolean }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.cfgService.config["openReturnItemColumns"][i].value
          )
        );
        const toInsert = this.cfgService.config["openReturnItemColumns"].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        console.log(toInsert);
        tempArray.push(
          toInsert[0]
        );
      }
      this.cfgService.config["openReturnItemColumns"] = tempArray;
      this.cfgService.saveConfig(this.cfgService.config);
      console.log(this.cfgService.config["openReturnItemColumns"]);
      this.cfgService.newOrder.next(true);
    }); this.openReturnItemReorder.complete().then((array) => {
      const columns = document.getElementsByClassName("openReturnItemReorderColumn");
      const tempArray: { value: string; size: string; display: boolean }[] = [];
      for (let i = 0; i < columns.length; i++) {
        console.log(
          columns
            .item(i)
            .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1),
          this.translate.instant(
            this.cfgService.config["openReturnItemColumns"][i].value
          )
        );
        const toInsert = this.cfgService.config["openReturnItemColumns"].filter(
          (value) =>
            this.translate.instant(value.value) ===
            columns
              .item(i)
              .innerHTML.substring(1, columns.item(i).innerHTML.indexOf("<") - 1)
        );
        console.log(toInsert);
        tempArray.push(
          toInsert[0]
        );
      }
      this.cfgService.config["openReturnItemColumns"] = tempArray;
      this.cfgService.saveConfig(this.cfgService.config);
      console.log(this.cfgService.config["openReturnItemColumns"]);
      this.cfgService.newOrder.next(true);
    });
  }

}
