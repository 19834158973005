import { ColumnConfigPage } from './../column-config/column-config.page';
import { Injectable } from "@angular/core";
import { Title } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { DokaI18NextService } from '@doka/i18next';

@Injectable({
  providedIn: "root"
})
export class PageService {
  public isNavigation = true;
  public currentPage = "return";
  public phoneWidth = 600;

  constructor(private modalCtrl: ModalController, private translate: DokaI18NextService, private title: Title) { }

  setCurrentPage(newCurrentPage: string) {
    this.currentPage = newCurrentPage;
    this.isNavigation = false;
  }

  getCurrentPage(): string {
    return this.currentPage;
  }

  openColumnOrder(type: string) {
    this.modalCtrl.create({
      component: ColumnConfigPage,
      componentProps: { type },
      cssClass: "column-modal-" + type
    }).then(modal => {
      modal.present();
    })
  }

  setTitle(page: string) {
    let title = "";
    if (page === "dashboard") {
      title = this.translate.instant("APPS");
    } else if (page.includes("returns")) {
      title = this.translate.instant("RETURNS");
    } else if (page.includes("pickings")) {
      title = this.translate.instant("PICKINGS");
    } else if (page.includes("transport-orders")) {
      title = this.translate.instant("TRANSPORT_ORDERS");
    } else if (page.includes("recons")) {
      title = this.translate.instant("RECON_ORDERS");
    } else if (page === "user-management") {
      title = this.translate.instant("USER_ADMINISTRATION");
    } else if (page === "user-management-administration") {
      title = this.translate.instant("ADMINISTRATION");
    } else if (page === "capacity-planning-overview") {
      title = this.translate.instant("CAPACITY_PLANNING_OVERVIEW");
    } else if (page === "capacity-planning-charts") {
      title = this.translate.instant("CAPACITY_PLANNING_CHARTS");
    } else if (page === "capacity-planning-administration") {
      title = this.translate.instant("CAPACITY_PLANNING_ADMINISTRATION");
    } else if (page === "yard-stock-overview") {
      title = this.translate.instant("YARD_STOCK_OVERVIEW");
    }
    this.title.setTitle("YardManager | " + title);
  }
}
