import { AuthenticationService } from './services/authentication.service';
import { environment } from 'src/environments/environment';
import { AdminService } from './services/admin.service';
import { FullscreenService } from './services/fullscreen.service';
import { ApplicationRef, Component, ViewContainerRef } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { ConfigService } from "./services/config.service";
import { PageService } from "./services/page.service";
import { SettingsPage } from './settings/settings.page';
import { AdminSelectPage } from './admin-select/admin-select.page';
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';
import { BoxPlotController, BoxAndWiskers, ViolinController } from '@sgratzl/chartjs-chart-boxplot';
import { DatabaseService } from './services/database.service';
import { WebService } from './services/web.service';
import { FilterService } from './services/filter.service';
import { DokaI18NextService } from '@doka/i18next';
import { DokaCommonService } from '@doka/common';


@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  environment = environment;
  screenSaverActive: boolean = false;
  moveInterval: any;
  isUserMenuOpen: boolean = false;

  constructor(
    private platform: Platform,
    public configService: ConfigService,
    public pageService: PageService,
    public router: Router,
    public fullScreenService: FullscreenService,
    private adminService: AdminService,
    private navCtrl: NavController,
    public authenticationService: AuthenticationService,
    private modalCtrl: ModalController,
    public databaseService: DatabaseService,
    private webService: WebService,
    private dokaTranslation: DokaI18NextService,
    private dokaCommonService: DokaCommonService,
    private viewContainerRef: ViewContainerRef,
    private appRef: ApplicationRef
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.dokaCommonService.init(this.viewContainerRef);
      this.configService.config = await this.configService.getConfig();
      await this.configService.initConfigService();
      await this.dokaTranslation.init(environment.translationsApiBaseUrl);
      this.configService.appLanguages = await this.webService.getAvailableLanguages();
      await this.dokaTranslation.changeLanguage(this.configService.config.appLanguage);
      await this.adminService.loadPermissions();
      this.authenticationService.clientId.subscribe(async () => {
        this.configService.branchConfiguration = await this.webService.getBranchConfigurationByCurrentBranchId();
        this.appRef.tick();
      });
    });
    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      BoxPlotController,
      ViolinController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      BoxAndWiskers,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip,
      SubTitle
    );
  }

  onLogo() {
    this.navCtrl.navigateRoot("/dashboard");
    this.pageService.isNavigation = true;
    this.pageService.currentPage = "returns";
  }

  startScreensaver() {
    this.screenSaverActive = true;
    this.move();
  }

  stopScreensaver() {
    this.screenSaverActive = false;
    clearInterval(this.moveInterval);
  }

  move() {

    var elem = document.getElementById("DVD");
    var ctrl = 0;
    this.moveInterval = setInterval(frame, 10);

    elem.style.top = "0"; //initial coordinates
    elem.style.left = "0";
    var rig = 0; //stores the right movement pixels
    var dow = 0; //stores the down movement pixels
    var top = 0; //stores up movement pixels
    var lef = 0; //stores left movement pixels
    function frame() {

      var cont = document.getElementById("Container");
      var h = cont.offsetHeight; //gets the height of container for responsive design
      var w = cont.offsetWidth; //gets the width of container for responsive design
      h = h - 69; // 69 is the width of the logo image
      w = w - 153; // 153 is the height of the logo image


      if (rig >= w && dow < h && top == 0) {
        ctrl = 1; //go left and down
        rig = 0;
        lef = 0;
      }
      else if (rig >= w && top < h && dow == 0) {
        ctrl = 2 //go left and up
        rig = 0;
        lef = 0;
      }
      else if (dow >= h && lef < w && rig == 0) {
        ctrl = 2; //go up and left
        dow = 0;
        top = 0;
      }
      else if (lef >= w && top < h && dow == 0) {

        ctrl = 3;  //go right and up
        lef = 0;
        rig = 0;
      }
      else if (top >= h && rig < w && lef == 0) {
        ctrl = 0; //go right and down
        top = 0;
        dow = 0;
      }
      else if (lef >= w && dow < h && top == 0) {
        ctrl = 0;  //go right and down              
        rig = 0;
        lef = 0;

      }
      else if (dow >= h && rig < w && lef == 0) {
        ctrl = 3; //go right and up
        dow = 0;
        top = 0;
      }
      else if (top >= h && lef < w && rig == 0) {
        ctrl = 1; //go left and down
        top = 0;
        dow = 0;
      }
      if (ctrl == 0)  //also default movement at the start
      {
        dow += 1; //random increment in dow
        rig += 2; //random increment in rig
        elem.style.top = dow + 'px'; // go right and down
        elem.style.left = rig + 'px';
      }

      else if (ctrl == 1) {
        dow += 0.8;
        lef += 1.5;
        elem.style.left = w - lef + 'px'; //go left and down
        elem.style.top = dow + 'px';
      }
      else if (ctrl == 2) //go left and up
      {
        lef += 1.2;
        top += 1.6;
        elem.style.top = h - top + 'px';
        elem.style.left = w - lef + 'px';
      }
      else if (ctrl == 3) //go up and right
      {
        rig += 1;
        top += 1.3;
        elem.style.top = h - top + 'px';
        elem.style.left = rig + 'px';
      }

    }
  }

  openHelp() {
    window.open("https://doka2gether.uag.corp/en/portfolios/sales/op-homebase/OPD/Apps/Freigegebene%20Dokumente/Forms/AllItems.aspx?RootFolder=%2Fen%2Fportfolios%2Fsales%2Fop%2Dhomebase%2FOPD%2FApps%2FFreigegebene%20Dokumente%2FYardManager%20Web%20App&FolderCTID=0x01200051C54DDA0253D14684279C1F55B050B0&View=%7B79FBA24D%2DF52E%2D478D%2D8AE8%2D34CFD14F3358%7D", "_blank");
    this.isUserMenuOpen = false;
  }

  openSettings() {
    this.isUserMenuOpen = false;
    this.modalCtrl.create({
      component: SettingsPage
    }).then(modalEl => {
      modalEl.present();
    });
  }

  switchMandant() {
    this.isUserMenuOpen = false;
    this.modalCtrl.create({
      component: AdminSelectPage
    }).then(modalEl => {
      modalEl.present();
    });
  }

  logout() {
    this.isUserMenuOpen = false;
    this.authenticationService.logout();
  }

}
