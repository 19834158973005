<ion-header>
  <ion-toolbar class="totalHeader">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ tempReturn.redeliveryType === 'Customer' || tempReturn.redeliveryType === 'Mixed' ? tempReturn.customerdeliverynote : tempReturn._id }}</ion-title>
    <ion-buttons slot="end" *ngIf="adminService.userHasPermission('Return App-Standard Access') || adminService.userHasPermission('Return App-Full Access') || adminService.userHasPermission('Administrator')">
      <ion-button (click)="saveReturn()">
        <ion-icon name="save-sharp" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <doka-spinner *ngIf="isLoading"></doka-spinner>
  <ion-list>
    <ion-item>
      {{ "t_id" | i18nextEager }}
      <span class="textRight">
        {{
          tempReturn.redeliveryType === "Customer" ||
          tempReturn.redeliveryType === "Mixed"
            ? tempReturn.customerdeliverynote
            : tempReturn._id
        }}
      </span>
    </ion-item>
    <ion-item>
      {{ "YARDCAM_LINKS" | i18nextEager }}
      <span class="textRight">
        <a class="yardcamLink" (click)="openYardcam('RD', tempReturn._id)">
          {{ "NEW_YC_LINK" | i18nextEager }}
        </a>
      </span>
    </ion-item>
    <ion-item>
      {{ "custname" | i18nextEager }}
      <span class="textRight">
        {{ tempReturn.custname }}
      </span>
    </ion-item>
    <ion-item>
      {{ "projectno" | i18nextEager }}
      <span class="textRight">
        {{ tempReturn.projectno }}
      </span>
    </ion-item>
    <ion-item>
      {{ "rentalsitedescription" | i18nextEager }}
      <span class="textRight">
        {{ tempReturn.rentalsitedescription }}
      </span>
    </ion-item>
    <ion-item>
      {{ "convertedDate" | i18nextEager }}
      <span class="textRight">
        {{ tempReturn.convertedDate | date }}
      </span>
    </ion-item>
    <ion-item *ngIf="tempReturn.cleaningagreed">
      {{ "CLEANING_AGREED" | i18nextEager }}
      <ion-icon name="checkbox" class="textRight"></ion-icon>
    </ion-item>
    <ion-item *ngIf="tempReturn.cleaningplusagreed">
      {{ "CLEANING_PLUS_AGREED" | i18nextEager }}
      <ion-icon name="checkbox" class="textRight"></ion-icon>
    </ion-item>
    <ion-item *ngIf="tempReturn.deviceserviceagreed">
      {{ "DEVICE_SERVICE_AGREED" | i18nextEager }}
      <ion-icon name="checkbox" class="textRight"></ion-icon>
    </ion-item>
    <ion-item *ngIf="tempReturn.formworkservicetop">
      {{ "FORM_WORK_SERVICE_TOP" | i18nextEager }}
      <ion-icon name="checkbox" class="textRight"></ion-icon>
    </ion-item>
    <ion-item *ngIf="tempReturn.formworkserviceplus">
      {{ "FORM_WORK_SERVICE_PLUS" | i18nextEager }}
      <ion-icon name="checkbox" class="textRight"></ion-icon>
    </ion-item>
    <ion-item *ngIf="tempReturn.formworkservicebasic">
      {{ "FORM_WORK_SERVICE_BASIC" | i18nextEager }}
      <ion-icon name="checkbox" class="textRight"></ion-icon>
    </ion-item>
    <ion-item *ngIf="tempReturn.formworkserviceexclusive">
      {{ "FORM_WORK_SERVICE_EXCLUSIVE" | i18nextEager }}
      <ion-icon name="checkbox" class="textRight"></ion-icon>
    </ion-item>
    <ion-item *ngIf="tempReturn.cleaningandfsbasic">
      {{ "CLEANING_AND_FS_BASIC" | i18nextEager }}
      <ion-icon name="checkbox" class="textRight"></ion-icon>
    </ion-item>
    <ion-item>
      <ion-label>{{ "UNLOADER" | i18nextEager }}</ion-label>
      <ion-input aria-label="Searchtext"
        (ionInput)="setDidChange()"
        class="ion-text-right"
        [(ngModel)]="tempReturn.unloaderemplid"
        [readonly]="isLocked(tempReturn) || !(adminService.userHasPermission('Return App-Standard Access') || adminService.userHasPermission('Return App-Full Access') || adminService.userHasPermission('Administrator'))"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>{{ "pamplacenumber" | i18nextEager }}</ion-label>
      <ion-input aria-label="Searchtext"
        (ionInput)="setDidChange()"
        class="ion-text-right"
        [(ngModel)]="tempReturn.pamplacenumber"
        [readonly]="isLocked(tempReturn) || !(adminService.userHasPermission('Return App-Standard Access') || adminService.userHasPermission('Return App-Full Access') || adminService.userHasPermission('Administrator'))"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>{{ "carrieraccount" | i18nextEager }}</ion-label>
      <ion-input aria-label="Searchtext"
        (ionInput)="setDidChange()"
        class="ion-text-right"
        [(ngModel)]="tempReturn.carrieraccount"
        [readonly]="isLocked(tempReturn) || !(adminService.userHasPermission('Return App-Standard Access') || adminService.userHasPermission('Return App-Full Access') || adminService.userHasPermission('Administrator'))"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>{{ "transunitcode" | i18nextEager }}</ion-label>
      <ion-input aria-label="Searchtext"
        (ionInput)="setDidChange()"
        class="ion-text-right"
        [(ngModel)]="tempReturn.transunitcode"
        [readonly]="isLocked(tempReturn) || !(adminService.userHasPermission('Return App-Standard Access') || adminService.userHasPermission('Return App-Full Access') || adminService.userHasPermission('Administrator'))"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label>{{ "ARRIVAL" | i18nextEager }}</ion-label>
      <ion-datetime-button
        datetime="arrivalDate"
        [disabled]="isLocked(tempReturn) || !(adminService.userHasPermission('Return App-Standard Access') || adminService.userHasPermission('Return App-Full Access') || adminService.userHasPermission('Administrator'))"
      ></ion-datetime-button>
    </ion-item>
    <ion-item>
      <ion-label>{{ "DEPARTURE" | i18nextEager }}</ion-label>
      <ion-datetime-button
        datetime="departureDate"
        [disabled]="isLocked(tempReturn) || !(adminService.userHasPermission('Return App-Standard Access') || adminService.userHasPermission('Return App-Full Access') || adminService.userHasPermission('Administrator'))"
      ></ion-datetime-button>
    </ion-item>
  </ion-list>
</ion-content>
<ion-modal keepContentsMounted="true">
  <ng-template>
    <ion-datetime
      (ionChange)="changeDate('arrivalDate')"
      multiple="false"
      id="arrivalDate"
      #arrivalDatePicker
    ></ion-datetime>
  </ng-template>
</ion-modal>
<ion-modal keepContentsMounted="true">
  <ng-template>
    <ion-datetime
      (ionChange)="changeDate('departureDate')"
      multiple="false"
      id="departureDate"
      #departureDatePicker
    ></ion-datetime>
  </ng-template>
</ion-modal>
