<ion-app [ngClass]="{'screenSaverOpacity': screenSaverActive}">
  <ion-router-outlet id="main-content" (click)="isUserMenuOpen=false"></ion-router-outlet>
  <app-navigation (click)="isUserMenuOpen=false" [isMenuOpen]="fullScreenService.isMenuOpen" [ngClass]="{'menuIsClosed': !fullScreenService.isMenuOpen}" *ngIf="!this.router.url.includes('login')&& !this.router.url.includes('not-supported')"></app-navigation>
  <ion-header *ngIf="!this.router.url.includes('login') && !this.router.url.includes('not-supported')">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="fullScreenService.isMenuOpen=!fullScreenService.isMenuOpen">
          <ion-icon name="menu-sharp"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-img src="assets/dokalogo.svg" class="cursorPointer dokaLogo" slot="start" (click)="onLogo()" button> </ion-img>
      <div class="screenSaverActivateButton" (click)="startScreensaver()"></div>
      <div class="userMenuButton" [ngClass]="{'userMenuButtonOpen': isUserMenuOpen}" (click)="isUserMenuOpen=!isUserMenuOpen" slot="end">
        <ion-thumbnail>
          <img [src]="authenticationService.profilePictureUrl !== null ? authenticationService.profilePictureUrl : 'assets/imgs/user_default.png'">
        </ion-thumbnail>
        <ion-label>
          {{ authenticationService.username }}<br>
          {{ "MANDANT" | i18nextEager }}: {{ configService.config?.clientid }}
        </ion-label>
        <ion-icon slot="end" name="caret-down"></ion-icon>
      </div>
    </ion-toolbar>
  </ion-header>
  <div *ngIf="authenticationService.isAuthenticated" class="userMenu" [ngClass]="{'userMenuOpen': isUserMenuOpen}">
    <ion-item lines="none" (click)="switchMandant()" button>
      <ion-icon slot="start" name="swap-horizontal-sharp"></ion-icon>
      <ion-label>{{ "SWITCH_MANDANT" | i18nextEager }}</ion-label>
    </ion-item>
    <ion-item lines="none" (click)="openHelp()" button>
      <ion-icon slot="start" name="help-circle-sharp"></ion-icon>
      <ion-label>{{ "HELP" | i18nextEager }}</ion-label>
    </ion-item>
    <ion-item lines="none" (click)="openSettings()" button>
      <ion-icon slot="start" name="settings-sharp"></ion-icon>
      <ion-label>{{ "SETTINGS" | i18nextEager }}</ion-label>
    </ion-item>
    <ion-item lines="none" (click)="logout()" button>
      <ion-icon slot="start" name="log-out-sharp"></ion-icon>
      <ion-label>{{ "LOGOUT" | i18nextEager }}</ion-label>
    </ion-item>
  </div>
</ion-app>
<div (click)="stopScreensaver()" [ngClass]="{'screenSaverInvisible': !screenSaverActive}" class="screenSaverContainer" id="Container">
  <ion-img id="DVD" src="/assets/dokalogo.svg"></ion-img>
</div>
