<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "REQUEST_PERMISSIONS" | i18nextEager }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="savePermissionRequest()">
        {{ "OK" | i18nextEager }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item>
      <ion-label>
        {{ "USER_FIRSTNAME" | i18nextEager }}:
        {{ authenticationService.userFirstname }}
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-label>
        {{ "USER_LASTNAME" | i18nextEager }}:
        {{ authenticationService.userLastname }}
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-label>
        {{ "USER_EMAIL" | i18nextEager }}: {{ authenticationService.useremail }}
      </ion-label>
    </ion-item>
  </ion-list>

  <div class="userRoles">
    <div class="cardHeader">
      <div class="headerText">
        <ion-label>
          {{ "USER_ROLES" | i18nextEager }}
          <div class="headerInfoButton">
            <ion-label>
              <ion-icon
                (click)="openPermissionInfo()"
                name="information-circle"
              ></ion-icon>
            </ion-label>
          </div>
        </ion-label>
      </div>
      <div class="headerAddButton">
        <ion-label>
          <ion-icon (click)="openAddRole()" name="add-circle-sharp"></ion-icon>
        </ion-label>
      </div>
    </div>
    <div class="cardContent">
      <ion-label *ngIf="roleBranches.length === 0" class="noItemsLabel">{{
        "NO_ROLES" | i18nextEager
      }}</ion-label>
      <div *ngIf="roleBranches.length > 0" class="list">
        <div
          class="listElement listElementButton"
          *ngFor="let roleBranch of roleBranches"
          (click)="selectRole(roleBranch)"
          [ngClass]="{
            selectedRole: selectedRoleBranch?.role.id === roleBranch.role.id
          }"
        >
          <span [ngClass]="{ errorRole: roleHasError(roleBranch.role) }">{{
            roleBranch.role.roleName
          }}</span>
          <ion-icon
            (click)="removeRole(roleBranch)"
            name="remove-circle-outline"
          ></ion-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="roleBranches">
    <div class="cardHeader">
      <div class="headerText">
        <ion-label>
          {{ "ROLE_BRANCHES" | i18nextEager }}
        </ion-label>
      </div>
    </div>
    <div class="cardContent">
      <ion-label *ngIf="selectedRoleBranch === null" class="noItemsLabel">{{
        "SELECT_ROLE" | i18nextEager
      }}</ion-label>
      <div *ngIf="selectedRoleBranch !== null" class="list">
        <div class="listElement" *ngFor="let branch of branches">
          <app-checkbox
            [checked]="roleBranchContainsBranch(branch)"
            (click)="selectBranch(branch)"
          ></app-checkbox>
          <ion-label>
            <h5>
              {{
                branch.branchIdentifier !== "000"
                  ? branch.branchIdentifier
                  : ("ALL_BRANCHES" | i18nextEager)
              }}
            </h5>
            <p *ngIf="branch.name && branch.displayName">
              {{ branch.name }}, {{ branch.displayName }}
            </p>
          </ion-label>
        </div>
      </div>
    </div>
  </div>
</ion-content>
