<ion-header>
  <ion-toolbar class="totalHeader">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ tempRecon.reconditioningOrderIdentifier }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-item>
      {{ "reconditioningOrderIdentifier" | i18nextEager }}
      <span class="textRight">
        {{ tempRecon.reconditioningOrderIdentifier }}
      </span>
    </ion-item>
    <ion-item>
      {{ "YARDCAM_LINKS" | i18nextEager }}
      <span class="textRight">
        <a
          class="yardcamLink"
          (click)="openYardcam('RO', tempRecon.reconditioningOrderIdentifier)"
        >
          {{ "NEW_YC_LINK" | i18nextEager }}
        </a>
      </span>
    </ion-item>
    <ion-item>
      {{ "plannedFinishDate" | i18nextEager }}
      <span class="textRight">
        {{ tempRecon.plannedFinishDate | date : "dd.MM.yyyy" }}
      </span>
    </ion-item>
    <ion-item>
      {{ "customerIdentifier" | i18nextEager }}
      <span class="textRight">
        {{
          tempRecon.customer?.customerIdentifier !== ""
            ? tempRecon.customer?.customerIdentifier
            : "-"
        }}
      </span>
    </ion-item>
    <ion-item>
      {{ "customer" | i18nextEager }}
      <span class="textRight">
        {{ tempRecon.customer?.name !== "" ? tempRecon.customer?.name : "-" }}
      </span>
    </ion-item>
    <ion-item>
      {{ "projectIdentifier" | i18nextEager }}
      <span class="textRight">
        {{
          tempRecon.project?.projectIdentifier !== ""
            ? tempRecon.project?.projectIdentifier
            : "-"
        }}
      </span>
    </ion-item>
    <ion-item>
      {{ "project" | i18nextEager }}
      <span class="textRight">
        {{ tempRecon.project?.name !== "" ? tempRecon.project?.name : "-" }}
      </span>
    </ion-item>
    <ion-item>
      {{ "type" | i18nextEager }}
      <span class="textRight">
        {{ tempRecon.type | i18nextEager }}
      </span>
    </ion-item>
    <ion-item>
      {{ "countType" | i18nextEager }}
      <span class="textRight">
        {{ tempRecon.countType | i18nextEager }}
      </span>
    </ion-item>
    <ion-item>
      {{ "departureYardIdentifier" | i18nextEager }}
      <span class="textRight">
        {{ tempRecon.departureYardIdentifier }}
      </span>
    </ion-item>
    <ion-item>
      {{ "receptionYardIdentifier" | i18nextEager }}
      <span class="textRight">
        {{ tempRecon.receptionYardIdentifier }}
      </span>
    </ion-item>
    <ion-item>
      {{ "alternativeYardIdentifier" | i18nextEager }}
      <span class="textRight">
        {{
          tempRecon.alternativeYardIdentifier
            ? tempRecon.alternativeYardIdentifier
            : "-"
        }}
      </span>
    </ion-item>
    <ion-item>
      {{ "netWeight" | i18nextEager }}
      <span class="textRight">
        {{ tempRecon.netWeight }}
      </span>
    </ion-item>
    <ion-item>
      {{ "doneWeight" | i18nextEager }}
      <span class="textRight">
        {{ tempRecon.doneWeight }}
      </span>
    </ion-item>
    <ion-item>
      {{ "canItemsBeAdded" | i18nextEager }}
      <span class="textRight">
        {{
          tempRecon.canItemsBeAdded ? ("YES" | i18nextEager) : ("NO" | i18nextEager)
        }}
      </span>
    </ion-item>
    <ion-item>
      {{ "qualityStandardCleaning" | i18nextEager }}
      <span class="textRight">
        {{
          tempRecon.qualityStandardCleaning
            ? tempRecon.qualityStandardCleaning
            : "-"
        }}
      </span>
    </ion-item>
    <ion-item>
      {{ "qualityStandardPlywood" | i18nextEager }}
      <span class="textRight">
        {{
          tempRecon.qualityStandardPlywood
            ? tempRecon.qualityStandardPlywood
            : "-"
        }}
      </span>
    </ion-item>
    <ion-item>
      {{ "qualityStandardFrame" | i18nextEager }}
      <span class="textRight">
        {{
          tempRecon.qualityStandardFrame ? tempRecon.qualityStandardFrame : "-"
        }}
      </span>
    </ion-item>
    <ion-item>
      {{ "qualityStandardAdditionalText" | i18nextEager }}
      <span class="textRight">
        {{
          tempRecon.qualityStandardAdditionalText
            ? tempRecon.qualityStandardAdditionalText
            : "-"
        }}
      </span>
    </ion-item>
  </ion-list>
</ion-content>
