<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "REQUEST_PERMISSIONS" | i18nextEager }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <doka-spinner *ngIf="isLoadingRoles"></doka-spinner>
  <ion-list *ngIf="!isLoadingRoles">
    <ion-item
      (click)="selectRole(role)"
      *ngFor="let role of roles"
      [ngClass]="{ displayNone: !role.canBeRequested }"
      button
    >
      {{ role.roleName }}
    </ion-item>
  </ion-list>
  <ion-infinite-scroll
    *ngIf="!isLoadingRoles"
    (ionInfinite)="loadMoreRoles($event)"
  >
    <div class="infinite-scroll-content">
      <doka-spinner
        [ngClass]="{ notVisible: !isLoadingMoreRoles }"
        class="infiniteScrollSpinner"
      ></doka-spinner>
    </div>
  </ion-infinite-scroll>
</ion-content>
