import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { TransportOrderLoader } from '../models/transportOrders/transport-order-loader-model';
import { DokaI18NextService } from '@doka/i18next';
import { WebService } from '../services/web.service';

@Component({
  selector: 'app-loaders-list',
  templateUrl: './loaders-list.component.html',
  styleUrls: ['./loaders-list.component.scss'],
})
export class LoadersListComponent  implements OnInit {

  @Input("transportOrderId") transportOrderId: string;
  @Input("loaders") loaders: TransportOrderLoader[];

  loadersToRemove: TransportOrderLoader[] = [];

  constructor(
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private translate: DokaI18NextService,
    private webService: WebService
  ) { }

  ngOnInit() {}

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  removeLoader(loader: TransportOrderLoader) {
    let loaderIndex = this.loaders.findIndex(l => l.userId === loader.userId);
    this.loaders.splice(loaderIndex, 1);
    this.loadersToRemove.push(loader);
  }

  async saveLoaders() {
    let loadingEl = await this.loadingCtrl.create({
      message: this.translate.instant("REMOVE_LOADERS_LOADING"),
      spinner: 'crescent'
    });
    await loadingEl.present();
    for(let loader of this.loadersToRemove) {
      await this.webService.deactivateLoaderFromTransportOrderById(this.transportOrderId, loader.userId);
    }
    await loadingEl.dismiss();
    this.modalCtrl.dismiss(true);
  }

}
