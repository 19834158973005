import { HttpClient } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";
import { DokaI18NextService } from '@doka/i18next';
import { AlertController, LoadingController } from "@ionic/angular";
import { DatabaseService } from "./database.service";
import { AuthenticationService } from "./authentication.service";
import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { WebService } from "./web.service";
import { ConfigService } from "./config.service";
import { UserRole } from "../models/userRole";
import { UserPermissionRequest } from "../models/userPermissionRequest";
import { UserPermissionRequestRole } from "../models/userPermissionRequestRole";
import { Branch } from "../models/branch";
import { PermissionRequest } from "../models/permissionRequest";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  adminUsers = [];
  kioskUsers = [];
  shiftSupervisorUsers = [];
  keyUsers = [];
  keyUserDB = [];
  shiftSupervisorUsersDB = [];
  public requestedPermissions = [];
  public userListsLoaded: number = 0;
  umlautMap = {
    '\u00dc': 'UE',
    '\u00c4': 'AE',
    '\u00d6': 'OE',
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u00df': 'ss',
    '\u1e9e': 'ss',
    '\u00e0': 'a',
    '\u00e1': 'a',
    '\u00e2': 'a',
    '\u00e3': 'a',
    '\u00e5': 'a',
    '\u01ce': 'a',
    '\u0105': 'a',
    '\u0103': 'a',
    '\u00e6': 'a',
    '\u0101': 'a',
    '\u00c0': 'A',
    '\u00c1': 'A',
    '\u00c2': 'A',
    '\u00c3': 'A',
    '\u00c5': 'A',
    '\u01cd': 'A',
    '\u0104': 'A',
    '\u0102': 'A',
    '\u00c6': 'A',
    '\u0100': 'A',
    '\u00e7': 'c',
    '\u0107': 'c',
    '\u0109': 'c',
    '\u010d': 'c',
    '\u00c7': 'C',
    '\u0106': 'C',
    '\u0108': 'C',
    '\u010c': 'C',
    '\u0111': 'd',
    '\u010f': 'd',
    '\u00f0': 'd',
    '\u010e': 'D',
    '\u0110': 'D',
    '\u00e8': 'e',
    '\u00e9': 'e',
    '\u00ea': 'e',
    '\u00eb': 'e',
    '\u011b': 'e',
    '\u0119': 'e',
    '\u0117': 'e',
    '\u0113': 'e',
    '\u00c8': 'E',
    '\u00c9': 'E',
    '\u00ca': 'E',
    '\u00cb': 'E',
    '\u011a': 'E',
    '\u0118': 'E',
    '\u0116': 'E',
    '\u0112': 'E',
    '\u011d': 'g',
    '\u0123': 'g',
    '\u011f': 'g',
    '\u011c': 'G',
    '\u0122': 'G',
    '\u011e': 'G',
    '\u0125': 'h',
    '\u0124': 'H',
    '\u00ec': 'i',
    '\u00ed': 'i',
    '\u00ee': 'i',
    '\u00ef': 'i',
    '\u0131': 'i',
    '\u012b': 'i',
    '\u012f': 'i',
    '\u00cc': 'I',
    '\u00cd': 'I',
    '\u00ce': 'I',
    '\u00cf': 'I',
    '\u012a': 'I',
    '\u012e': 'I',
    '\u0135': 'j',
    '\u0134': 'J',
    '\u0137': 'k',
    '\u0136': 'K',
    '\u013a': 'l',
    '\u013c': 'l',
    '\u0142': 'l',
    '\u013e': 'l',
    '\u0140': 'l',
    '\u0139': 'L',
    '\u013b': 'L',
    '\u0141': 'L',
    '\u013d': 'L',
    '\u013f': 'L',
    '\u00f1': 'n',
    '\u0144': 'n',
    '\u0148': 'n',
    '\u0146': 'n',
    '\u00d1': 'N',
    '\u0143': 'N',
    '\u0147': 'N',
    '\u0145': 'N',
    '\u00f2': 'o',
    '\u00f3': 'o',
    '\u00f4': 'o',
    '\u00f5': 'o',
    '\u0151': 'o',
    '\u00f8': 'o',
    '\u0153': 'o',
    '\u00d2': 'O',
    '\u00d3': 'O',
    '\u00d4': 'O',
    '\u00d5': 'O',
    '\u0150': 'O',
    '\u00d8': 'O',
    '\u0152': 'O',
    '\u0155': 'r',
    '\u0159': 'r',
    '\u0154': 'R',
    '\u0158': 'R',
    '\u015b': 's',
    '\u015d': 's',
    '\u015f': 's',
    '\u0161': 's',
    '\u0219': 's',
    '\u015a': 'S',
    '\u015c': 'S',
    '\u015e': 'S',
    '\u0160': 'S',
    '\u0218': 'S',
    '\u0165': 't',
    '\u0163': 't',
    '\u00fe': 't',
    '\u021b': 't',
    '\u0164': 'T',
    '\u0162': 'T',
    '\u00de': 'T',
    '\u021a': 'T',
    '\u00f9': 'u',
    '\u00fa': 'u',
    '\u00fb': 'u',
    '\u0171': 'u',
    '\u0169': 'u',
    '\u0173': 'u',
    '\u016f': 'u',
    '\u016b': 'u',
    '\u00d9': 'U',
    '\u00da': 'U',
    '\u00db': 'U',
    '\u0170': 'U',
    '\u0168': 'U',
    '\u0172': 'U',
    '\u016e': 'U',
    '\u016a': 'U',
    '\u0175': 'w',
    '\u0174': 'W',
    '\u00fd': 'y',
    '\u00ff': 'y',
    '\u0177': 'y',
    '\u00dd': 'Y',
    '\u0178': 'Y',
    '\u0176': 'Y',
    '\u017a': 'z',
    '\u017e': 'z',
    '\u017c': 'z',
    '\u0179': 'Z',
    '\u017d': 'Z',
    '\u017b': 'Z'
  };

  private _newUser = new BehaviorSubject<string>(null);

  get newUser() {
    return this._newUser.asObservable();
  }

  autoUpdate: any;

  userRoles: UserRole[] = [];
  userRolesLoaded: boolean = false;

  returnAppUserRoles: UserRole[] = [];
  pickingAppUserRoles: UserRole[] = [];
  outboundAppUserRoles: UserRole[] = [];
  reconAppUserRoles: UserRole[] = [];
  yardManagerUserRoles: UserRole[] = [];
  deliveryAppUserRoles: UserRole[] = [];
  returnDeliveryAppUserRoles: UserRole[] = [];
  inboundAppUserRoles: UserRole[] = [];

  loadedReturnAppRoles: boolean = false;
  loadedPickingAppUserRoles: boolean = false;
  loadedOutboundAppUserRoles: boolean = false;
  loadedReconAppUserRoles: boolean = false;
  loadedYardManagerUserRoles: boolean = false;
  loadedDeliveryAppUserRoles: boolean = false;
  loadedReturnDeliveryAppUserRoles: boolean = false;
  loadedInboundAppRoles: boolean = false;

  public userPermissionRequests: UserPermissionRequest[] = [];
  permissionRequests: PermissionRequest[] = [];

  constructor(
    private databaseService: DatabaseService,
    private alertCtrl: AlertController,
    private translate: DokaI18NextService,
    private loadingCtrl: LoadingController,
    private oAuthService: OAuthService,
    private http: HttpClient,
    private zone: NgZone,
    private webService: WebService,
    private configService: ConfigService,
    private authenticationService: AuthenticationService
  ) {
    this.loadPermissionRequests();
    this.autoUpdate = setInterval(() => {
      this.loadPermissionRequests();
    }, 60000);
    this.authenticationService.clientId.subscribe(res => {
      let config = this.configService.getConfig();
      this.loadedReturnAppRoles = false;
      this.loadedDeliveryAppUserRoles = false;
      this.loadedOutboundAppUserRoles = false;
      this.loadedReconAppUserRoles = false;
      this.loadedYardManagerUserRoles = false;
      this.loadedPickingAppUserRoles = false;
      this.loadedReturnDeliveryAppUserRoles = false;
      this.loadedInboundAppRoles = false;
      this.loadPermissions(config.branchId);
    });
  }

  async loadPermissionRequests() {
    let userPermissionRequests = [];
    this.permissionRequests = await this.webService.getApproveablePermissionRequests();
    for (let permissionRequest of this.permissionRequests) {
      let userPR = userPermissionRequests.find(upr => upr.userId === permissionRequest.user.id);
      if (!userPR) {
        userPR = new UserPermissionRequest(permissionRequest.user.id, permissionRequest.user.firstName, permissionRequest.user.lastName, permissionRequest.user.userName, []);
        userPermissionRequests.push(userPR);
      }

      let userPRRole = userPR.requestedRoles.find(rr => rr.id === permissionRequest.role.id);
      if (!userPRRole) {
        userPRRole = new UserPermissionRequestRole(permissionRequest.role.id, permissionRequest.role.roleName, []);
        userPR.requestedRoles.push(userPRRole);
      }

      let userPRRoleBranch = userPRRole.branches.find(b => b.id === permissionRequest.branch.id);
      if (!userPRRoleBranch) {
        userPRRoleBranch = new Branch(permissionRequest.branch.id, permissionRequest.branch.branchIdentifier);
        userPRRole.branches.push(userPRRoleBranch);
      }
    }
    this.userPermissionRequests = userPermissionRequests;
  }

  public async loadPermissions(branchId?: string) {
    const claims = this.oAuthService.getIdentityClaims();
    if (claims) {
      this.userRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], branchId);
      if (branchId) {
        if (!this.loadedReturnAppRoles) {
          this.returnAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], branchId, "Return App");
          this.loadedReturnAppRoles = true;
        }
        if (!this.loadedPickingAppUserRoles) {
          this.pickingAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], branchId, "Picking App");
          this.loadedPickingAppUserRoles = true;
        }
        if (!this.loadedOutboundAppUserRoles) {
          this.outboundAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], branchId, "Outbound App");
          this.loadedOutboundAppUserRoles = true;
        }
        if (!this.loadedReconAppUserRoles) {
          this.reconAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], branchId, "Recon App");
          this.loadedReconAppUserRoles = true;
        }
        if (!this.loadedYardManagerUserRoles) {
          this.yardManagerUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], branchId, 'YardManager');
          this.loadedYardManagerUserRoles = true;
        }
        if (!this.loadedDeliveryAppUserRoles) {
          this.deliveryAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], branchId, 'Delivery App');
          this.loadedDeliveryAppUserRoles = true;
        }
        if (!this.loadedReturnDeliveryAppUserRoles) {
          this.returnDeliveryAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], branchId, 'Return Delivery App');
          this.loadedReturnDeliveryAppUserRoles = true;
        }
        if (!this.loadedInboundAppRoles) {
          this.inboundAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], branchId, 'Inbound App');
        }
      }
    }
    this.userRolesLoaded = true;
  }

  async canAccessSiteAsync(route: string): Promise<boolean> {
    const claims = this.oAuthService.getIdentityClaims();
    const config = this.configService.getConfig();
    let userRoles: UserRole[] = [];
    if (route === "dashboard") {
      this.userRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId);
      userRoles = this.userRoles;
    } else if (route === "voucher-detail") {
      this.userRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId);
      userRoles = this.userRoles;
    } else if (route === "returns") {
      if (!this.loadedReturnAppRoles) {
        this.returnAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId, "Return App");
        this.loadedReturnAppRoles = true;
      }
      userRoles = this.returnAppUserRoles;
    } else if (route === "pickings") {
      if (!this.loadedPickingAppUserRoles) {
        this.pickingAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId, "Picking App");
        this.loadedPickingAppUserRoles = true;
      }
      userRoles = this.pickingAppUserRoles;
    } else if (route === "transport-orders") {
      if (!this.loadedOutboundAppUserRoles) {
        this.outboundAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId, "Outbound App");
        this.loadedOutboundAppUserRoles = true;
      }
      userRoles = this.outboundAppUserRoles;
    } else if (route === "recons") {
      if (!this.loadedReconAppUserRoles) {
        this.reconAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId, "Recon App");
        this.loadedReconAppUserRoles = true;
      }
      userRoles = this.reconAppUserRoles;
    } else if (route === "delivery-pickings") {
      if (!this.loadedDeliveryAppUserRoles) {
        this.deliveryAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId, "Delivery App");
        this.loadedDeliveryAppUserRoles = true;
      }
      userRoles = this.deliveryAppUserRoles;
    } else if (route.includes("return-deliveries")) {
      if (!this.loadedDeliveryAppUserRoles) {
        this.returnDeliveryAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId, "Return Delivery App");
        this.loadedReturnDeliveryAppUserRoles = true;
      }
      userRoles = this.returnDeliveryAppUserRoles;
    } else if (route === "delivery-transport-orders") {
      userRoles = this.deliveryAppUserRoles;
    } else if (route === "user-management-administration") {
      if (!this.loadedYardManagerUserRoles) {
        this.yardManagerUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId, "YardManager");
        this.loadedYardManagerUserRoles = true;
      }
      userRoles = this.yardManagerUserRoles;
      userRoles = userRoles.filter(ur => ur.roleName === "Administrator");
    } else if (route === "user-management") {
      if (!this.loadedYardManagerUserRoles) {
        this.yardManagerUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId, "YardManager");
        this.loadedYardManagerUserRoles = true;
      }
      userRoles = this.yardManagerUserRoles;
      userRoles = userRoles.filter(ur => ur.roleName === 'YardManagement' || ur.roleName === "Administrator");
    } else if (route === "admin") {
      if (!this.loadedYardManagerUserRoles) {
        this.yardManagerUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId, "YardManager");
        this.loadedYardManagerUserRoles = true;
      }
      userRoles = this.yardManagerUserRoles;
      userRoles = userRoles.filter(ur => ur.roleName === "Administrator");
    } else if (route === "capacity-planning-administration") {
      if (!this.loadedYardManagerUserRoles) {
        this.yardManagerUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId, "YardManager");
        this.loadedYardManagerUserRoles = true;
      }
      userRoles = this.yardManagerUserRoles;
      userRoles = userRoles.filter(ur => ur.roleName === "Capacity Planning-Full Access" || ur.roleName === "Administrator");
    } else if (route === "capacity-planning-overview") {
      this.userRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId);
      userRoles = this.userRoles;
    } else if (route === "capacity-planning-charts") {
      this.userRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId);
      userRoles = this.userRoles;
    } else if (route === "yard-stock-overview") {
      this.userRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId);
      userRoles = this.userRoles;
    } else if (route === "odl") {
      this.userRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId);
      userRoles = this.userRoles;
    } else if (route === "shipping-board") {
      if (!this.loadedYardManagerUserRoles) {
        this.yardManagerUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId, "YardManager");
        this.loadedYardManagerUserRoles = true;
      }
      userRoles = this.yardManagerUserRoles;
      userRoles = userRoles.filter(ur => ur.roleName === "Shipping-Board" || ur.roleName === "Administrator");
    } else if (route === "capacity-planning-odlinsights") {
      this.userRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId);
      userRoles = this.userRoles;
    } else if (route.includes("inbound-orders")) {
      if (!this.loadedInboundAppRoles) {
        this.inboundAppUserRoles = await this.webService.getRolesByUserIdAndBranchId(claims['sub'], config.branchId, "Inbound App");
        this.loadedInboundAppRoles = true;
      }
      userRoles = this.inboundAppUserRoles;
    }
    return userRoles.length > 0;
  }

  canAccessSite(route: string) {
    let userRoles: UserRole[] = [];
    if (route === "dashboard") {
      userRoles = this.userRoles;
    } else if (route === "voucher-detail") {
      userRoles = this.userRoles;
    } else if (route === "returns") {
      userRoles = this.returnAppUserRoles;
    } else if (route === "pickings") {
      userRoles = this.pickingAppUserRoles;
    } else if (route === "transport-orders") {
      userRoles = this.outboundAppUserRoles;
    } else if (route === "recons") {
      userRoles = this.reconAppUserRoles;
    } else if (route === "delivery-pickings") {
      userRoles = this.deliveryAppUserRoles;
    } else if (route === "delivery-transport-orders") {
      userRoles = this.deliveryAppUserRoles;
    } else if (route === "return-deliveries") {
      userRoles = this.returnDeliveryAppUserRoles;
    } else if (route === "user-management-administration") {
      userRoles = this.yardManagerUserRoles;
      userRoles = userRoles.filter(ur => ur.roleName === "Administrator");
    } else if (route === "user-management") {
      userRoles = this.yardManagerUserRoles;
      userRoles = userRoles.filter(ur => ur.roleName === 'YardManagement' || ur.roleName === "Administrator");
    } else if (route === "admin") {
      userRoles = this.yardManagerUserRoles;
      userRoles = userRoles.filter(ur => ur.roleName === "Administrator");
    } else if (route === "capacity-planning-administration") {
      userRoles = this.yardManagerUserRoles;
      userRoles = userRoles.filter(ur => ur.roleName === "Capacity Planning-Full Access" || ur.roleName === "Administrator");
    } else if (route === "capacity-planning-overview") {
      userRoles = this.userRoles;
    } else if (route === "capacity-planning-charts") {
      userRoles = this.userRoles;
    } else if (route === "yard-stock-overview") {
      userRoles = this.userRoles;
    } else if (route === "odl") {
      userRoles = this.userRoles;
    } else if (route === "shipping-board") {
      userRoles = this.yardManagerUserRoles;
      userRoles = userRoles.filter(ur => ur.roleName === 'Shipping-Board' || ur.roleName === "Administrator");
    } else if (route === "capacity-planning-odlinsights") {
      userRoles = this.userRoles;
    } else if (route === "inbound-orders") {
      userRoles = this.inboundAppUserRoles;
    }
    return userRoles.length > 0;
  }

  userHasPermission(roleName: string, applicationName?: string) {
    if (!applicationName) {
      return this.userRoles.findIndex(ur => ur.roleName === roleName) !== -1;
    } else if (applicationName === "Return App") {
      return this.returnAppUserRoles.findIndex(ur => ur.roleName === roleName) !== -1;
    } else if (applicationName === "Picking App") {
      return this.pickingAppUserRoles.findIndex(ur => ur.roleName === roleName) !== -1;
    } else if (applicationName === "Outbound App") {
      return this.outboundAppUserRoles.findIndex(ur => ur.roleName === roleName) !== -1;
    } else if (applicationName === "Recon App") {
      return this.reconAppUserRoles.findIndex(ur => ur.roleName === roleName) !== -1;
    } else if (applicationName === "Delivery App") {
      return this.deliveryAppUserRoles.findIndex(ur => ur.roleName === roleName) !== -1;
    } else if (applicationName === "Return Delivery App") {
      return this.returnDeliveryAppUserRoles.findIndex(ur => ur.roleName === roleName) !== -1;
    } else if (applicationName === "YardManager") {
      return this.yardManagerUserRoles.findIndex(ur => ur.roleName === roleName) !== -1;
    } else if (applicationName === "Inbound App") {
      return this.inboundAppUserRoles.findIndex(ur => ur.roleName === roleName) !== -1;
    } else {
      return false;
    }
  }

  hasPermissions(useremail: string) {
    let permissions = [];
    if (this.kioskUsers.includes(useremail)) {
      permissions.push("kiosk");
    } else if (this.shiftSupervisorUsers.includes(useremail)) {
      permissions.push("shiftSupervisor");
    } else if (this.keyUsers.includes(useremail)) {
      permissions.push("key");
    } else if (this.adminUsers.includes(useremail)) {
      permissions.push("admin");
    }
    return permissions;
  }

  public addUser(email: string, group: string) {
    if (group == "admin") {
      if (this.adminUsers.includes(email)) {
        this.alertCtrl
          .create({
            header: this.translate.instant("ALREADY_IN_LIST"),
            message: this.translate.instant("ALREADY_IN_LIST_MESSAGE"),
            buttons: [
              {
                text: this.translate.instant("OK"),
              },
            ],
          })
          .then((alertEL) => {
            alertEL.present();
          });
      } else {
        this.loadingCtrl
          .create({
            message: this.translate.instant("ADD_USER_LOADING"),
          })
          .then((loadingEl) => {
            loadingEl.present();
            this.databaseService.addAdminUser(email).then((res) => {
              this.adminUsers = res.users;
              loadingEl.dismiss();
              this._newUser.next(email);
            });
          });
      }
    } else if (group == "shiftSupervisor") {
      if (this.shiftSupervisorUsers.includes(email)) {
        this.alertCtrl
          .create({
            header: this.translate.instant("ALREADY_IN_LIST"),
            message: this.translate.instant("ALREADY_IN_LIST_MESSAGE"),
            buttons: [
              {
                text: this.translate.instant("OK"),
              },
            ],
          })
          .then((alertEL) => {
            alertEL.present();
          });
      } else {
        this.loadingCtrl
          .create({
            message: this.translate.instant("ADD_USER_LOADING"),
          })
          .then((loadingEl) => {
            loadingEl.present();
            this.databaseService.addShiftsupervisorUser(email).then((res) => {
              this.shiftSupervisorUsers = res.users;
              loadingEl.dismiss();
              this._newUser.next(email);
            });
          });
      }
    } else if (group == "kiosk") {
      if (this.kioskUsers.includes(email)) {
        this.alertCtrl
          .create({
            header: this.translate.instant("ALREADY_IN_LIST"),
            message: this.translate.instant("ALREADY_IN_LIST_MESSAGE"),
            buttons: [
              {
                text: this.translate.instant("OK"),
              },
            ],
          })
          .then((alertEL) => {
            alertEL.present();
          });
      } else {
        this.loadingCtrl
          .create({
            message: this.translate.instant("ADD_USER_LOADING"),
          })
          .then((loadingEl) => {
            loadingEl.present();
            this.databaseService.addKioskUser(email).then((res) => {
              this.kioskUsers = res.users;
              loadingEl.dismiss();
              this._newUser.next(email);
            });
          });
      }
    } else if (group == "key") {
      if (this.keyUsers.includes(email)) {
        this.alertCtrl
          .create({
            header: this.translate.instant("ALREADY_IN_LIST"),
            message: this.translate.instant("ALREADY_IN_LIST_MESSAGE"),
            buttons: [
              {
                text: this.translate.instant("OK"),
              },
            ],
          })
          .then((alertEL) => {
            alertEL.present();
          });
      } else {
        this.loadingCtrl
          .create({
            message: this.translate.instant("ADD_USER_LOADING"),
          })
          .then((loadingEl) => {
            loadingEl.present();
            this.databaseService.addKeyUser(email).then((res) => {
              this.keyUserDB = res;
              this.keyUsers = res.users;
              loadingEl.dismiss();
              this._newUser.next(email);
            });
          });
      }
    }
  }

  public removeUser(email: string, group: string) {
    if (group == "admin") {
      this.loadingCtrl
        .create({
          message: this.translate.instant("REMOVE_USER_LOADING"),
        })
        .then((loadingEl) => {
          loadingEl.present();
          this.databaseService.removeAdminUser(email).then((res) => {
            this.adminUsers = res.users;
            loadingEl.dismiss();
            this._newUser.next(email);
          });
        });
    } else if (group == "shiftSupervisor") {
      this.loadingCtrl
        .create({
          message: this.translate.instant("REMOVE_USER_LOADING"),
        })
        .then((loadingEl) => {
          loadingEl.present();
          this.databaseService.removeShiftsupervisorUser(email).then((res) => {
            this.shiftSupervisorUsers = res.users;
            loadingEl.dismiss();
            this._newUser.next(email);
          });
        });
    } else if (group == "kiosk") {
      this.loadingCtrl
        .create({
          message: this.translate.instant("REMOVE_USER_LOADING"),
        })
        .then((loadingEl) => {
          loadingEl.present();
          this.databaseService.removeKioskUser(email).then((res) => {
            this.kioskUsers = res.users;
            loadingEl.dismiss();
            this._newUser.next(email);
          });
        });
    } else if (group == "key") {
      this.loadingCtrl
        .create({
          message: this.translate.instant("REMOVE_USER_LOADING"),
        })
        .then((loadingEl) => {
          loadingEl.present();
          this.databaseService.removeKeyUser(email).then((res) => {
            this.keyUserDB = res;
            this.keyUsers = res.users;
            loadingEl.dismiss();
            this._newUser.next(email);
          });
        });
    }
  }

  public inviteUser(
    surname: string,
    firstname: string
  ) {
    return new Promise<void>((resolve, reject) => {
      const requestUrl = environment.yardAPIUrl + "/users/invite";
      var auth_token = this.oAuthService.getAccessToken();
      const headers = {
        Authorization: `Bearer ${auth_token}`,
        "accept-version": "1.0",
      };

      const body = {
        firstName: this.replaceNonAscii(firstname),
        lastName: this.replaceNonAscii(surname),
        userName: `${this.replaceNonAscii(firstname).toLowerCase()}.${this.replaceNonAscii(surname).toLowerCase()}@yo.grpid`
      };

      console.log(headers);
      this.http.post(requestUrl, body, { headers: headers, responseType: "text" as "text" }).subscribe(
        (res: any) => {
          console.log(res);
          window.open(res, "_blank");
          resolve();
        },
        (err) => {
          console.error(err);
          reject();
        }
      );
    });
  }

  public getAverageUsersPerDay() {
    return this.databaseService.getAverageLogins();
  }

  replaceNonAscii(str) {
    return str
      .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
        const big = this.umlautMap[a.slice(0, 1)];
        return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
      })
      .replace(new RegExp('[' + Object.keys(this.umlautMap).join('|') + ']', "g"),
        (a) => this.umlautMap[a]
      );
  }
}
