import { AuthenticationGuard } from './guards/authentication.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SupportedDeviceGuard } from './guards/supported-device.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canActivate: [SupportedDeviceGuard]
  },
  {
    path: 'pickings',
    loadChildren: () => import('./pickings/pickings.module').then(m => m.PickingsPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'returns',
    loadChildren: () => import('./returns/returns.module').then(m => m.ReturnsPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'transport-orders',
    loadChildren: () => import('./transport-orders/transport-orders.module').then(m => m.TransportOrdersPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [SupportedDeviceGuard]
  },
  {
    path: 'admin-select',
    loadChildren: () => import('./admin-select/admin-select.module').then(m => m.AdminSelectPageModule),
    canActivate: [SupportedDeviceGuard]
  },
  {
    path: 'recons',
    loadChildren: () => import('./recons/recons.module').then(m => m.ReconsPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'recon-spareparts',
    loadChildren: () => import('./recon-spareparts/recon-spareparts.module').then(m => m.ReconSparepartsPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'odl',
    loadChildren: () => import('./odl/odl.module').then(m => m.OdlPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'yard-stock-overview',
    loadChildren: () => import('./yard-stock-overview/yard-stock-overview.module').then(m => m.YardStockOverviewPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'not-supported',
    loadChildren: () => import('./not-supported/not-supported.module').then(m => m.NotSupportedPageModule)
  },
  {
    path: 'user-management',
    loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'user-management-administration',
    loadChildren: () => import('./user-management-administration/user-management-administration.module').then(m => m.UserManagementAdministrationPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'capacity-planning-administration',
    loadChildren: () => import('./capacity-planning/capacity-planning-administration/capacity-planning-administration.module').then(m => m.CapacityPlanningAdministrationPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'capacity-planning-overview',
    loadChildren: () => import('./capacity-planning/capacity-planning-overview/capacity-planning-overview.module').then(m => m.CapacityPlanningOverviewPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'capacity-planning-charts',
    loadChildren: () => import('./capacity-planning/capacity-planning-charts/capacity-planning-charts.module').then(m => m.CapacityPlanningChartsPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'voucher-detail',
    loadChildren: () => import('./voucher-detail/voucher-detail.module').then(m => m.VoucherDetailPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'return-deliveries',
    loadChildren: () => import('./return-deliveries/return-deliveries.module').then(m => m.ReturnDeliveriesPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'return-deliveries/:returnDeliveryId/items',
    loadChildren: () => import('./return-delivery-items/return-delivery-items.module').then( m => m.ReturnDeliveryItemsPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'shipping-board',
    loadChildren: () => import('./shipping-board/shipping-board.module').then( m => m.ShippingBoardPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  },
  {
    path: 'capacity-planning-odlinsights',
    loadChildren: () => import('./capacity-planning/capacity-planning-odlinsights/capacity-planning-odlinsights.module').then( m => m.CapacityPlanningODLInsightsPageModule),
    canActivate: [AuthenticationGuard, SupportedDeviceGuard]
  }





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
