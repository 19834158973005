<!-- Importiere die benötigten Module -->
<ion-header>
  <ion-toolbar class="totalHeader">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon name="close-sharp" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ "PERMISSION_INFO" | i18nextEager }}
    </ion-title>
    <ion-buttons slot="end"> </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="loadingRolesSpinner" *ngIf="isLoadingRoles">
    <doka-spinner></doka-spinner>
  </div>
  <div *ngIf="!isLoadingRoles">
    <ion-accordion-group *ngFor="let role of roles">
      <ion-accordion>
        <ion-item slot="header" color="light">
          <ion-label>{{ role.roleName }}</ion-label>
        </ion-item>
        <div class="ion-padding" slot="content">
          {{
            role.roleName
              .replace("–", "")
              .replace("-", "")
              .replaceAll(".", "")
              .replaceAll(" ", "")
              .toUpperCase() + "_DESCRIPTION" | i18nextEager
          }}
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </div>
</ion-content>
